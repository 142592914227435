/**
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { DataTable, DataTableBody, DataTableContent } from '@rmwc/data-table';
import React from 'react';

import { Extension } from '../../models';
import styles from './ExtensionTable.module.scss';
import { ExtensionsTableRow } from './ExtensionTableRow';

export interface ExtensionsTableProps {
  extensions: Extension[];
}

export const ExtensionsTable: React.FC<
  React.PropsWithChildren<ExtensionsTableProps>
> = ({ extensions }) => {
  return (
    <DataTable className={styles.extensionTable}>
      <DataTableContent>
        <DataTableBody>
          {extensions.map((extension) => (
            <ExtensionsTableRow key={extension.id} extension={extension} />
          ))}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
};
